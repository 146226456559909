/* src/fonts.css */
@font-face {
    font-family: 'Axiforma';
    src: url('./../public/fonts/Kastelov\ \ Axiforma\ Book.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
   }
   
   @font-face {
    font-family: 'Axiforma';
    src: url('./../public/fonts/Kastelov\ \ Axiforma\ Bold.otf') format('truetype');
    font-weight: bold;
    font-style: normal;
   }
   