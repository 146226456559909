@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./font.css";

/* .inter {
  font-family: "Inter", sans-serif;
}
.poppins {
  font-family: "Poppins", sans-serif;
}
.Rubik {
  font-family: "Rubik", sans-serif;
} */

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.character {
  border: none;
  font-size: 20px;
  border-radius: 1rem;

  /* light theme */
  color: #000;
  background-color: #f6f5fa;
  box-shadow: 0 2px 0 #e4e2f5;

  /* dark theme */
  color: #000;
  background-color: #f4f4f4;
}
#animate {
  @apply animate-spin;
}
.splash-background {
  background: url("./assets/images/splash_screen.png");
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  display: none;
}
body {
  font-family: "Axiforma", sans-serif;
  overflow-x: hidden;
  position: relative;
}

#bold {
  @apply font-semibold;
}
#not-bold {
  @apply font-normal;
}

/* The container */
.box-container {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The container radio */
.contain-radio {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  padding-top: 4px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.contain-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.circle {
  @apply absolute top-0 left-0 h-6 w-6 rounded-full border-zinc-800 border-2 bg-white;
}
.contain-radio .circle:after {
  left: 4px;
  top: 4px;
  @apply w-3 h-3 rounded-full bg-zinc-800;
}
.contain-radio input:checked ~ .circle:after {
  display: block;
}
.circle:after {
  content: "";
  position: absolute;
  display: none;
}
.meals::-webkit-scrollbar {
  display: none;
}

#show-cat {
  @apply rounded-b-none;
}

#show-flex {
  display: flex;
}

#show {
  display: block;
}
#hide {
  display: none;
}

.let {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.swipe-in-bottom {
  animation: swipeInBottom 500ms ease;
}

.swipe-out-bottom {
  animation: swipeInBottom 500ms ease;
}

.swipeIn {
  animation-name: swipeIn;
  -webkit-animation-name: swipeIn;
}

.form-group input {
  outline: none;
  border: 1px solid transparent;
}
.form-group input:focus {
  border: 1px solid #c2e1ac;
}

@keyframes swipeIn {
  0% {
    transform: translateX(200%);
  }

  100% {
    transform: translateX(0px);
  }
}

@-webkit-keyframes swipeIn {
  0% {
    -webkit-transform: translateX(200%);
  }

  100% {
    -webkit-transform: translateX(0px);
  }
}

.swipeInLeft {
  animation-name: swipeInLeft;
  -webkit-animation-name: swipeInLeft;
}

@keyframes swipeInLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0px);
  }
}

@-webkit-keyframes swipeInLeft {
  0% {
    -webkit-transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(0px);
  }
}

@keyframes swipeInBottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0px);
  }
}

@-webkit-keyframes swipeInBottom {
  0% {
    -webkit-transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0px);
  }
}

@keyframes swipeOutBottom {
  to {
    transform: translateY(100%);
  }
}

@-webkit-keyframes swipeOutBottom {
  to {
    -webkit-transform: translateY(100%);
  }
}

#show-border {
  @apply text-[#7BBE4A] border-[#7BBE4A] bg-[#F7F7F7] border-b-2;
}

#hide-food {
  display: none;
}

.you {
  @apply bg-[#7BBE4A] text-white text-right flex flex-wrap py-2 pl-8 pr-2 max-w-[80vw]  h-fit  my-2 rounded-t-[32px] rounded-bl-[32px];
}
.other {
  @apply bg-gray-200 text-black text-left flex flex-wrap py-2 pr-8 pl-2 max-w-[80vw]  h-fit  my-2 rounded-t-[32px] rounded-br-[32px];
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 12px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullet-active {
  background-color: #fff !important;
}
.swiper-pagination {
  bottom: 30px !important;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.explore {
  font-family: "SF Pro Display", sans-serif;
}
.hero_bg {
  background: linear-gradient(
    180deg,
    rgba(123, 190, 74, 0.2) 0%,
    rgba(123, 190, 74, 0.02) 100%
  );
}
.jkt-underline {
  color: #7bbe4a;
  background: url("./assets/images/underline.png") bottom left no-repeat;
  background-size: 100% 10px;
  padding-bottom: 8px;
  width: 100%;
}

.download {
  background: url("./assets/Svg/download_bg.svg") bottom left no-repeat;
}

.hide-scroll::-moz-scrollbar {
  display: none;
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}

.logo-animation {
  animation: fadeInAndSlide 0.8s ease-out;
}
@keyframes fadeInAndSlide {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInAndSlide {
  from {
    -webkit-transform: translateY(-4px);
    -webkit-opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(20);
  }
  100% {
    transform: translateY(-20px);
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.drop-container {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox rounded-full w-3 h-3 bg-black*/
.drop-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.drop-checkmark {
  position: absolute;
  top: 0.7rem;
  right: 1.5rem;

  height: 20px;
  width: 20px;
  border-radius: 50%;
  @apply border border-gray-400;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.drop-container:hover input ~ .drop-checkmark {
  background-color: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.drop-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.drop-container input:checked ~ .drop-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.drop-container .drop-checkmark:after {
  position: absolute;
  margin: auto auto;
  inset: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #7bba4e;
}

/* payment method radio */

.pay-container {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox rounded-full w-3 h-3 bg-black*/
.pay-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.pay-checkmark {
  position: absolute;
  top: 0.7rem;
  left: 0.7rem;

  height: 20px;
  width: 20px;
  border-radius: 50%;
  @apply border border-gray-400;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.pay-container:hover input ~ .pay-checkmark {
  background-color: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.pay-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.pay-container input:checked ~ .pay-checkmark:after {
  display: block;
}
.pay-checkmark::before {
  position: absolute;
  content: "";
  width: 5px;
  z-index: 200;
  height: 10px;
  left: 6px;
  top: 3px;
  transform: rotate(50deg);
  border-right: 1px solid white;
  border-bottom: 1px solid white;
}

/* Style the checkmark/indicator */
.pay-container .pay-checkmark:after {
  position: absolute;
  margin: auto auto;
  inset: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #7bba4e;
}

.wallet-card-bal-gradient {
  background: linear-gradient(
    83deg,
    #d6ebc7 14.58%,
    rgba(214, 235, 199, 0.49) 94.79%
  );
}

@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

.checkbox-container {
  position: relative;
  width: 20px;
  height: 20px;
}

.checkbox-container input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid #bababa;
  border-radius: 6px;
}

.checkbox-container input[type="checkbox"]:checked {
  background-color: #7bbe4a;
  border: 1px solid #7bbe4a;
}

.checkbox-container .checkmark {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.checkbox-container input[type="checkbox"]:checked ~ .checkmark {
  display: inline-block;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ham-menu {
  width: 22px;
  height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.menu {
  width: 60%;
  height: 2px;
  border-radius: 9999px;
  background: white;
  transition: cubic-bezier(0.135, 0.88, 0.575, 1.385) 300ms;
}
.menu-top,
.menu-bottom {
  width: 100%;
  height: 2px;
  border-radius: 9999px;
  background: white;
  transition: cubic-bezier(0.135, 0.88, 0.575, 1.385) 300ms;
}

.close .menu-top {
  translate: 0 8px;
  rotate: 45deg;
}

.close .menu-bottom {
  translate: 0 -8px;
  rotate: -45deg;
}

.close .menu {
  background-color: transparent;
}
