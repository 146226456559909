.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

.toggle-switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e6e6e6;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  background-color: #7bbe4a;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #d6ebc7;
}

input:focus + .slider {
  box-shadow: 0 0 1px #d6ebc7;
}

input:checked + .slider:before {
  transform: translateX(20px);
}
